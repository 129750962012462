<template>
  <b-field>
      <b-slider 
        size="is-large"
        :tooltip="false"
        rounded
        v-model="value"
        :min="block.minimum"
        :max="block.maximum"
        :step="block.step"
      ></b-slider>
  </b-field>
</template>

<script>
export default {
  components: {},
  props: {
    block:{
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      value: 0,
      EmitCoolingDown: false,
      pendingEmit: false,
    };
  },
  computed: {},
  methods: {
    SendValue(){
      
      this.$socket.client.emit('gameMessage', {
        type: 'controllerData',
        controllerType: 'slider',
        name: this.block.blockName,
        input: {
          value: this.value,
        }
      })
    }
  },
  watch:{
      value: function () {
        if (this.EmitCoolingDown) {
          this.pendingEmit = true
          return // cooldown
        }
        this.SendValue();

        this.EmitCoolingDown = true;
        setTimeout(() => {
          this.EmitCoolingDown = false
          if(this.pendingEmit){
            this.pendingEmit = false
             this.SendValue()
          }
        }, this.block.dataInterval)
      }
    },
  mounted() {
    //
  },
};
</script>

<style scoped lang="scss">
  .field{
    width: 100%;
    padding: 2rem;
  }
</style>
